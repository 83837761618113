import React from "react";
import "./App.css";

function App() {
  const numeros = Array.from({ length: 50 }, (_, index) => index + 1);

  return (
    <div className="flex flex-col h-screen">
      {/* Barra de Ferramentas */}
      <header className="bg-blue-500 text-white p-4">
        Toolbar
      </header>
      {/* Conteúdo Principal */}
      <main className="flex-grow overflow-y-auto py-4">
        <div className="container mx-auto px-4">
          <ul className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {numeros.map((numero) => (
              <li className="bg-white p-4 shadow rounded" key={numero}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </li>
            ))}
          </ul>
        </div>
      </main>
      {/* Rodapé */}
      <footer className="bg-gray-200 p-4">
        Rodapé
      </footer>
    </div>
  );
}

export default App;
